import _ from 'lodash';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { useLink } from 'components/ArtboardAssets/CallToAction/useLink';
import * as Constants from 'const';
import * as documentsActions from 'containers/Documents/actions';
import * as relationsActions from 'containers/Relations/actions';
import { getActiveLayer } from 'hooks/useActiveLayer';
import * as Models from 'models';
import { getContainerSize } from 'utils/getContainerSize';
import { stylesComparator } from 'utils/styles/stylesComparator';
import { toPx } from 'utils/toPx';
import { CallToActionEditorHook } from './hooks/CallToActionEditorHook';
import { useModifiedStylesSetters } from './hooks/useModifiedStylesSetters';
import { SizeResetHook } from './hooks/useSizeReset';
import { StylesHook } from './hooks/useStyles';
import { CallToActionProps } from './models';
import { ctaStylesToSource } from './styles';

export const useCallToAction = (
  props: CallToActionProps,
  stylesHook: StylesHook,
  sizeResetHook: SizeResetHook,
  editorHook: CallToActionEditorHook,
  editorWrap: React.RefObject<HTMLDivElement>) => {
  const {
    cellHeight,
    cellWidth,
    currentCountry,
    currentLanguage,
    document,
    editMode,
    flatColorsByRelationId,
    flatFontsByRelationId,
    layoutId,
    layoutRelations,
    relation,
    updateRelationsSilently,
    isAutoFitContent,
  } = props;

  const { resetHeightOnEffect, resetWidth, cellHeightBeforeEdit, cellWidthBeforeEdit } = sizeResetHook;

  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const colors = flatColorsByRelationId.get(relationId);
  const fonts = flatFontsByRelationId.get(relationId);

  const isFirstRendering = useRef(true);
  const container = useRef<HTMLDivElement>(null);

  const { styles, setters: stylesSetters, getMaxWidth } = stylesHook;
  const {
    assetPadding,
    border,
    fitToCell,
    height,
    padding,
    width,
  } = styles;

  const { link, toggleLink } = useLink(document);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editMode) {
      resetWidth(padding, border, (newWidth: number): void => {
        // if RL/GRL is placed into smaller width than it comes from,
        // the width should be adjusted silently, as it's done automatically it should not trigger update modal
        const updatedLayoutRelations = layoutRelations.setIn([relationId, 'styles', activeLayer, 'width'], newWidth);
        updateRelationsSilently(updatedLayoutRelations, layoutId);
      });
    }
  }, [editMode, width]);

  useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false;

      return;
    }
    if (editMode) {
      editorHook.returnFocusToEditor();

      return;
    }

    const updatedRelation = relation.updateIn(['styles', activeLayer], values => values.withMutations(
      value => ctaStylesToSource(styles, value)
        .set('isAutoFitContent', isAutoFitContent),
    ));
    const { title, rawContent, text } = editorHook.getData();
    const updatedDocument = document.withMutations(
      document => document
        .set('name', title)
        .set('text', text)
        .set('rawContent', rawContent)
        .set('link', link.trim())
        .set('isMockDocument', false)
        // https://issues.merck.com/browse/DCC-4748
        .set('language', currentLanguage)
        .set('country', currentCountry),
    );
    const actions: Models.IAction[] = [];

    if (
      cellWidthBeforeEdit !== cellWidth ||
        cellHeightBeforeEdit !== cellHeight ||
            !_.isEqualWith(updatedRelation.getIn(['styles', activeLayer]).toJS(), relation.getIn(['styles', activeLayer]).toJS(), stylesComparator)
    ) {
      actions.push(relationsActions.updateLayeredRelations(layoutRelations.set(updatedRelation.get('id'), updatedRelation)));
    }

    if (
      updatedDocument.get('link') !== document.get('link') ||
          updatedDocument.get('name') !== document.get('name') ||
          updatedDocument.get('rawContent') !== document.get('rawContent')
    ) {
      actions.push(documentsActions.setDocument(updatedDocument));
    }

    if (actions.length !== 0) {
      dispatch(batchActions(actions));
    }

  }, [editMode]);

  useEffect(() => {
    const { current } = editorWrap;

    if (current === null) {
      return;
    }

    const px = toPx(current?.scrollHeight);

    if (px === undefined) {
      return;
    }

    current.style.height = px;

  }, [editMode]);

  const onResize = (newHeight: number): void => {
    stylesSetters.fitToCell(false);
    stylesSetters.height(_.ceil(newHeight));
  };

  useEffect(
    () => {
      resetHeightOnEffect();
    },
    [
      height,
      width,
      padding,
      border,
      isAutoFitContent,
      fitToCell,
    ],
  );

  const finishResizing = (actualWidth: number, actualHeight: number): void => {
    stylesSetters.height(_.round(actualHeight));
    stylesSetters.width(_.round(actualWidth));
    stylesSetters.fitToCell(false);
  };

  const getMaxHeight = (): number => {
    if (!container.current) {
      return null;
    }

    return getContainerSize(container.current).height;
  };

  const getHeight = (): number => fitToCell ? getMaxHeight() : _.round(height);
  const getWidth = (): number => fitToCell ? getMaxWidth() : _.round(width);

  const assetVerticalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.TOP) + assetPadding.get(Constants.BoxPropertySide.BOTTOM))
    : 0;
  const assetHorizontalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.LEFT) + assetPadding.get(Constants.BoxPropertySide.RIGHT))
    : 0;

  const getResizeComponentHeight = (): number => assetVerticalPadding > getHeight() ? assetVerticalPadding : getHeight();
  const getResizeComponentMinHeight = (): number => assetVerticalPadding + (editorWrap.current ? editorWrap.current.scrollHeight : 0);
  const getResizeComponentWidth = (): number => assetHorizontalPadding > getWidth() ? assetHorizontalPadding : getWidth();

  const modifiedStylesSetters = useModifiedStylesSetters(stylesHook, sizeResetHook, getMaxHeight);

  return {
    container,

    modifiedStylesSetters,

    link,
    toggleLink,

    getHeight,
    getMaxHeight,

    getWidth,

    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,

    finishResizing,
    onResize,

    brandProps: {
      colors,
      fonts,
    },
  };
};
