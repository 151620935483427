import _ from 'lodash';
import React from 'react';

import ImageInput from 'components/ImageInput';
import { ImageExtension, ImageInputStatus, RenditionType, ResolutionType } from 'const';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { IImageInputBlockProps, IImageInputBlockState } from './models';

const ADD_IMAGE_WINDOW_PLACEHOLDER = 'AddImageWindow.Placeholder';

const lowAndHighFileExtensions = [ImageExtension.JPG, ImageExtension.JPEG, ImageExtension.PNG];
const sourceFileExtensions = [ImageExtension.AI, ImageExtension.EPS, ImageExtension.PSD].concat(lowAndHighFileExtensions);

const imagesResolutionData: Record<ResolutionType, Models.ImageResolutionData> = {
  [ResolutionType.SOURCE]: {
    getPlaceholderName: () => intlGet(ADD_IMAGE_WINDOW_PLACEHOLDER, 'SourceFile'),
    imageFilter: sourceFileExtensions.join(', '),
    displayedImageFilter: `${ImageExtension.PSD} ${ImageExtension.AI} ${ImageExtension.PNG} ${ImageExtension.JPG}`,
    required: true,
  },
  [ResolutionType.LOW]: {
    getPlaceholderName: () => intlGet(ADD_IMAGE_WINDOW_PLACEHOLDER, 'LowRendition'),
    imageFilter: lowAndHighFileExtensions.join(', '),
    displayedImageFilter: `${ImageExtension.PNG} ${ImageExtension.JPG} `,
    required: true,
  },
  [ResolutionType.HIGH]: {
    getPlaceholderName: () => intlGet(ADD_IMAGE_WINDOW_PLACEHOLDER, 'HighRendition'),
    imageFilter: lowAndHighFileExtensions.join(', '),
    displayedImageFilter: `${ImageExtension.PNG} ${ImageExtension.JPG}`,
    required: false,
  },
};

export default class ImageInputBlock extends React.PureComponent<IImageInputBlockProps, IImageInputBlockState> {
  componentDidMount(): void {
    this.props.setImageFiles({}, this.getImageInputStatus(ResolutionType.LOW));
  }

  private readonly handleInputChange = (resolution: ResolutionType, imageFile: File): void => {
    const { imageFiles, setImageFiles } = this.props;
    const newImageFiles = { ...imageFiles, [resolution]: imageFile };

    setImageFiles(newImageFiles, this.getImageInputStatus(ResolutionType.LOW, newImageFiles));
  };

  private readonly getImageInputStatus = (resolution: ResolutionType, imageFiles = this.props.imageFiles): ImageInputStatus => {
    let imageInputStatus: ImageInputStatus;

    switch (resolution) {
      case ResolutionType.LOW:
      case ResolutionType.HIGH:
        if (!imageFiles[resolution] && (!this.getInitialImageName(resolution) || !!imageFiles.source)) {
          imageInputStatus = ImageInputStatus.EMPTY;
        } else if (!imageFiles.source && imageFiles[resolution] && this.getInitialImageName(resolution)) {
          imageInputStatus = ImageInputStatus.CHANGED;
        } else if (!imageFiles.source && this.getInitialImageName(resolution)) {
          imageInputStatus = ImageInputStatus.INITIAL;
        } else {
          imageInputStatus = ImageInputStatus.ADDED;
        }

        break;
      case ResolutionType.SOURCE:
        if (!imageFiles[resolution] && !this.getInitialImageName(resolution)) {
          imageInputStatus = ImageInputStatus.EMPTY;
        } else if (!imageFiles[resolution] && this.getInitialImageName(resolution)) {
          imageInputStatus = ImageInputStatus.INITIAL;
        } else if (!this.getInitialImageName(resolution)) {
          imageInputStatus = ImageInputStatus.ADDED;
        } else {
          imageInputStatus = ImageInputStatus.CHANGED;
        }

        break;
    }

    return imageInputStatus;
  };

  private readonly getInitialImageName = (resolution: ResolutionType): string => {
    const { initialImage } = this.props;

    if (!initialImage) {
      return '';
    }

    const imageRenditions = initialImage.get('renditions');

    switch (resolution) {
      case ResolutionType.LOW: return imageRenditions.getIn([RenditionType.LOW, 'fileName'], '');
      case ResolutionType.HIGH: return imageRenditions.getIn([RenditionType.HIGH, 'fileName'], '');
      case ResolutionType.SOURCE: return initialImage.get('filename') || initialImage.get('name');
      default: return '';
    }
  };

  private readonly getImageFileName = (resolution: ResolutionType): string => {
    const { imageFiles } = this.props;
    const imageStatus = this.getImageInputStatus(resolution);

    switch (imageStatus) {
      case ImageInputStatus.INITIAL:
        return this.getInitialImageName(resolution);
      case ImageInputStatus.ADDED:
      case ImageInputStatus.CHANGED:
        return imageFiles[resolution].name;
      default: return '';
    }
  };

  render(): JSX.Element {
    return (
      <>
        {
          _.map(imagesResolutionData, (imageResolutionData, resolution: ResolutionType) =>
            (<ImageInput
              key={resolution}
              resolution={resolution}
              onInputChange={this.handleInputChange}
              imageResolutionData={imageResolutionData}
              imageFileName={this.getImageFileName(resolution)}
              imageInputStatus={this.getImageInputStatus(resolution)}
            />),
          )
        }
      </>
    );
  }
}
