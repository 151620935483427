import { OrderedSet } from 'immutable';
import { LexicalEditor } from 'lexical';
import { LexicalAbbreviationValues } from 'modules/Abbreviations/Lexical/AbbreviationPlugin';
import { ABBREVIATION_CHANGE } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/commands';
import { $selectAbbreviation } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/utils';
import { BASE_PROPS_COMMAND, BaseProps } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { CustomListOnChangeProps } from 'modules/Lexical/Plugins/CustomListPlugin';
import { BULLET_COLOR_COMMAND } from 'modules/Lexical/Plugins/CustomListPlugin/commands';
import { FONT_COMMAND, FontProps } from 'modules/Lexical/Plugins/FontPlugin';
import { LINK_COMMAND, LinkProps } from 'modules/Lexical/Plugins/LinkNodePlugin';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { TextEditorHook, TextEditorSetters, TextEditorProps } from './TextEditorHook';

export type LexicalOnChange = {
  abbreviations: (values: LexicalAbbreviationValues) => void;
  baseProps: (values: BaseProps) => void;
  bulletToggle: (values: CustomListOnChangeProps) => void;
  font: (values: FontProps) => void;
  link: (values: LinkProps) => void;
  textContent: (value: string) => void;
};

type LexicalTextEditorHook = TextEditorHook & {
  ref: RefObject<LexicalEditor>;
  onChange: LexicalOnChange;
};

export function useLexicalTextEditor(): LexicalTextEditorHook {
  const ref = useRef<LexicalEditor>(null);
  const editor = ref.current;

  const [props, setProps] = useState<TextEditorProps>({
    abbreviationId: undefined,
    abbreviationTerm: undefined,
    blockLineHeight: undefined,
    blockType: undefined,
    bulletColor: undefined,
    fontColor: undefined,
    fontFamily: undefined,
    fontSize: undefined,
    inlineStyle: OrderedSet<Constants.InlineStyle>([]),
    link: undefined,
    linkApplicable: false,
    scriptStyle: undefined,
    textNoWrap: false,
  });

  // IN-PROGRESS: get initial value from props
  const [textContent, setTextContent] = useState('');

  const setters = useMemo((): TextEditorSetters => ({
    abbreviationId: (id: string | undefined) => editor?.dispatchCommand(ABBREVIATION_CHANGE, id),
    blockLineHeight: value => editor?.dispatchCommand(BASE_PROPS_COMMAND.BLOCK_LINE_HEIGHT, value),
    blockType: type => editor?.dispatchCommand(BASE_PROPS_COMMAND.BLOCK_TYPE, type),
    bulletColor: (color: Models.BrandColorMap) => editor?.dispatchCommand(BULLET_COLOR_COMMAND, color),
    fontColor: brandColor => editor?.dispatchCommand(FONT_COMMAND.BRAND_COLOR, brandColor),
    fontFamily: (
      brandFont: Models.BrandFontMap,
      characterStyle: Models.CharacterStyleMap,
    ) => editor?.dispatchCommand(FONT_COMMAND.BRAND_FONT, { brandFont, characterStyle }),
    fontSize: (value: number) => editor?.dispatchCommand(FONT_COMMAND.SIZE, value),
    inlineStyle: (style: Constants.InlineStyle) => editor?.dispatchCommand(
      FONT_COMMAND.INLINE_STYLE,
      style,
    ),
    link: (value: string | undefined) => editor?.dispatchCommand(LINK_COMMAND, value),
    scriptStyle: (value: Constants.ScriptType) => editor?.dispatchCommand(BASE_PROPS_COMMAND.SCRIPT_STYLE, value),
    textNoWrap: () => editor?.dispatchCommand(BASE_PROPS_COMMAND.TEXT_NOWRAP, undefined),
  }), [editor]);

  const onChange: LexicalOnChange = useMemo(() => ({
    abbreviations: values => setProps(prev => ({
      ...prev,
      abbreviationId: values.abbreviationId,
      abbreviationTerm: values.abbreviationTerm,
    })),
    baseProps: (values: BaseProps) => setProps(prev => ({
      ...prev,
      blockLineHeight: values.blockLineHeight,
      blockType:  values.blockType ?? prev.blockType,
      scriptStyle: values.scriptStyle,
      textNoWrap: values.textNoWrap,
    })),
    bulletToggle: values => setProps(prev => ({
      ...prev,
      blockType: values ? Constants.TextHorizontalAlignmentType.UNORDERED_LIST : prev.blockType,
      bulletColor: values?.color,
    })),
    font: values => setProps(prev => ({
      ...prev,
      fontColor: values.brandColor,
      fontFamily: values.brandFont,
      fontSize: values.size,
      inlineStyle: values.inlineStyle,
    })),
    link: values => setProps(prev => ({
      ...prev,
      link: values.link,
      linkApplicable: values.linkApplicable,
    })),
    textContent: value => setTextContent(value),
  }), [setProps]);

  const applyBrandStyleValues = useCallback(() => {
    // IN-PROGRESS: TBD
    throw new Error('TBD');
  }, []);

  const returnFocusToEditor = useCallback(() => {
    // https://github.com/facebook/lexical/issues/4460
    ref.current?._rootElement?.focus();
  }, []);

  // CELL ACTION EXECUTORS

  const setCursorOnAbbreviation = (abbreviationId: string, abbreviationNumber: number | undefined): boolean => {
    let results = false;
    ref.current?.update(() => {
      results = $selectAbbreviation(abbreviationId, abbreviationNumber);
    }, { discrete: true });

    return results;
  };

  const applySelection = (): void => {
    // lexical abbreviation works without this action executor
    // so we decide to skip its implementation
  };

  return {
    // TextEditorHook --------------
    hasChanges: true, // IN-PROGRESS should be implemented
    hasTextContent: Boolean(textContent.length),
    hasCustomToken: editorUtils.hasToken(textContent),
    props,
    setters,
    applyBrandStyleValues,
    returnFocusToEditor,
    flushDataToSave: () => ({ text: '', rawContent: '', operations: [] }), // IN-PROGRESS should be implemented
    // TextEditorHook - CELL ACTION EXECUTORS
    setCursorOnAbbreviation,
    applySelection,
    // custom --------------
    ref,
    onChange,
  };
}
