import DraftEditor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import Immutable from 'immutable';
import { Features, useDraftjsEditor, useDraftjsEditorState } from 'modules/draftjs';
import { RefObject } from 'react';
import * as Constants from 'const';

type UseTextEditorReturnType = {
  disableLink: boolean;
  editor: RefObject<DraftEditor>;
  editorState: Draft.EditorState;
  fontStyles: Immutable.OrderedSet<string>;
  link: string;
  focusEditor: () => void;
  onEditorStateChange: (editorState: Draft.EditorState) => void;
  toggleLink: (link: string) => void;
  clearFormatting: () => void;
  toggleFontStyle: (style: string) => void;
  toggleScriptStyle: (style: Constants.ScriptType) => void;
};

export const useEditor = (initialEditorState?: (() => Draft.EditorState)): UseTextEditorReturnType => {

  const draftjsState = useDraftjsEditorState(
    () => initialEditorState ? initialEditorState() : Draft.EditorState.createEmpty(),
    false,
  );
  const { editorState } = draftjsState;
  const {
    editorRef,
    resetEditorState,
    returnFocusToEditor,
    props,
    setters,
  } = useDraftjsEditor(
    draftjsState,
    {},
    [Features.CLEAR_FORMATTING, Features.LINK],
  );

  return {
    disableLink: !props.linkApplicable,
    editor: editorRef,
    editorState,
    fontStyles: props.inlineStyle as unknown as Immutable.OrderedSet<string>,
    link: props.link ?? '',
    focusEditor: returnFocusToEditor,
    onEditorStateChange: resetEditorState,
    toggleLink: (link: string) => setters.link(link || undefined),
    clearFormatting: (): void => {
      setters.clearFormatting();
      returnFocusToEditor();
    },
    toggleFontStyle: (value: string): void => {
      setters.inlineStyle(value);
      returnFocusToEditor();
    },
    toggleScriptStyle: (style: Constants.ScriptType): void => {
      setters.scriptStyle(style);
      returnFocusToEditor();
    },
  };
};
