import { $findMatchingParent } from '@lexical/utils';
import { $getSelection, $isElementNode, $isNodeSelection, $isRangeSelection, ElementFormatType, ElementNode } from 'lexical';
import { $isCustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import * as Constants from 'const';
import { BaseProps } from './types';

const elementNodeFormatMapToBlockType: {
  [key in ElementFormatType]?: Constants.TextHorizontalAlignmentType
} = {
  left: Constants.TextHorizontalAlignmentType.LEFT,
  center: Constants.TextHorizontalAlignmentType.CENTER,
  end: Constants.TextHorizontalAlignmentType.RIGHT,
  justify: Constants.TextHorizontalAlignmentType.JUSTIFY,
};

function getBlockTypeFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['blockType'] {
  if (!$isRangeSelection(selection) && !$isNodeSelection(selection)) {
    return Constants.TextHorizontalAlignmentType.LEFT;
  }

  for (const node of selection.getNodes()) {
    const element = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode => $isElementNode(parentNode) && !parentNode.isInline(),
    );
    if (element) {
      const type: ElementFormatType = (element as ElementNode).getFormatType() ;

      return (type && elementNodeFormatMapToBlockType[type]) || Constants.TextHorizontalAlignmentType.LEFT;
    }
  }

  return Constants.TextHorizontalAlignmentType.LEFT;
}

function getScriptStyleFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['scriptStyle'] {
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  if (selection.hasFormat('superscript')) {
    return Constants.ScriptType.SUPERSCRIPT;
  }

  if (selection.hasFormat('subscript')) {
    return Constants.ScriptType.SUBSCRIPT;
  }

  return undefined;
}

function getBlockLineHeightFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['blockLineHeight'] {
  if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    const paragraphNode = anchorNode.getTopLevelElement();

    if ($isCustomParagraphNode(paragraphNode)) {
      const lineHeight = paragraphNode.getLineHeight();
      if (lineHeight) {
        return lineHeight as Constants.TextLineHeightValue;
      }
    }
  }

  return Constants.DefaultLineHeight;
}

export function $getBaseProps(): BaseProps {
  const selection = $getSelection();

  return {
    blockLineHeight: getBlockLineHeightFromSelection(selection),
    blockType: getBlockTypeFromSelection(selection),
    scriptStyle: getScriptStyleFromSelection(selection),
    textNoWrap: false, // IN-PROGRESS: should be read from selection
  };
}
